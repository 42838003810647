const locales = {
  // register: {
  //   message: 'dontHaveAccount',
  //   link: '/signup',
  //   linkTitle: 'signUp',
  // },
  login: {
    message: 'alreadyHaveAccount',
    link: '/signin',
    linkTitle: 'signIn',
  },
  logout: {
    message: 'notYou',
    link: '/logout',
    linkTitle: 'logout',
  },
};

export default locales;
