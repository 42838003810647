import request from './request';
import { parseLink, getPageCount } from './pagination';

// Fixtures
// import orders from './fixtures/orders.json';

const pagginagtionResponse = res => {
  const pageLinks = parseLink(res.headers.link);
  const pageCount = getPageCount(pageLinks);
  const { data } = res;

  return {
    pageLinks,
    pageCount,
    data,
  };
};

// Donwload repsonse as file
const blobDownloadAs = fileName => response => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

const reportFileName = (fileName, from, to) => {
  let result = fileName;
  if (from) {
    result += `-${from.replace(/[\s\-:_]/g, '')}`;
  }
  if (to && to !== from) {
    result += `-${to.replace(/[\s\-:_]/g, '')}`;
  }
  return result;
};

export const getOrdersList = ({
  fromDate,
  toDate,
  search,
  page,
  limit = 5,
  status,
  for_reports = false,
}) => {
  const queryParams = {
    page,
    per_page: limit,
    created_at_after: fromDate,
    created_at_before: toDate,
    q: search,
    status,
    for_reports,
  };

  return request.get(`/orders/`, queryParams).then(pagginagtionResponse);
};

export const getMerchantFlags = () => {
  return request.get(`/merchant/profile/`).then(data => data);
};

export const getCheckoutListNoQS = () => {
  return request.get(`/checkouts/?per_page=20`).then(pagginagtionResponse);
};
export const getRolesList = ({ page, limit = 100 }) => {
  const queryParams = { page, per_page: limit };
  return request.get(`/merchant/roles/`, queryParams).then(pagginagtionResponse);
};
export const getEmployeesList = ({ merchantId, page, limit = 5, search }) => {
  const queryParams = {
    merchant_id: merchantId,
    page,
    per_page: limit,
    q: search,
  };
  return request.get(`/merchant/employees/`, queryParams).then(pagginagtionResponse);
};

export const getRole = roleId => {
  return request.get(`/merchant/roles/${roleId}/`);
};

export const getCheckoutList = ({ fromDate, search, toDate, page, limit = 5, status, source }) => {
  const queryParams = {
    page,
    per_page: limit,
    created_at_after: fromDate,
    created_at_before: toDate,
    q: search,
    source,
  };
  if (status !== 'ALL') {
    queryParams['checkout-status'] = status;
  }
  if (source !== 'ALL') {
    queryParams['source'] = source;
  }
  return request.get(`/checkouts/`, queryParams).then(pagginagtionResponse);
};

export const checkUniqueCheckoutReference = (reference, merchantId) => {
  const queryParam = {
    reference,
    merchantId,
  };
  return request.get(`/checkouts/check_ref/`, queryParam);
};

export const checkUniqueEmail = email => {
  const queryParam = {
    email,
  };
  return request.get(`/merchant/employees/check_email/`, queryParam);
};

export const generateUniqueCheckoutReference = merchantId => {
  const queryParam = {
    merchantId,
  };
  return request.get(`/checkouts/generate_ref/`, queryParam);
};
export const sendPaymentLinkToCustomer = reference => {
  const queryParam = {
    reference,
  };
  return request.get(`/checkouts/send_link/`, queryParam);
};

export const createMerchantRole = data =>
  request.post(`/merchant/roles/`, data).then(resp => resp.data);

export const createOrderForPayment = data =>
  request.post('/checkouts/', data).then(resp => resp.data);

export const createMerchantEmployee = data =>
  request.post('/merchant/employees/', data).then(resp => resp.data);

export const getCheckout = id => request.get(`/checkouts/${id}/`).then(res => res.data);

export const getOrder = id => request.get(`/orders/${id}/`).then(res => res.data);

export const orderCapture = (id, amount) =>
  request.post(`/orders/${id}/capture/`, { amount }).then(res => res.data);

export const orderRefund = (id, total, currency) =>
  request.post(`/orders/${id}/refund/`, { total, currency }).then(res => res.data);

export const orderVoid = id => request.post(`/orders/${id}/void/`).then(res => res.data);

export const settlementsReport = ({ fromDate, toDate, page, limit = 5 }) => {
  const queryParams = {
    page,
    per_page: limit,
    settlement_date_after: fromDate,
    settlement_date_before: toDate,
  };

  return request.get(`/settlements/`, queryParams).then(pagginagtionResponse);
};

export const refundsReport = ({ fromDate, toDate, page, limit = 5 }) => {
  const queryParams = {
    page,
    per_page: limit,
    created_at_after: fromDate,
    created_at_before: toDate,
  };

  return request.get(`/refunds/`, queryParams).then(pagginagtionResponse);
};

export const downloadSettlementsReport = (from, to) => {
  const fileName = reportFileName('settlements', from, to);
  const params = {
    settlement_date_after: from,
    settlement_date_before: to,
  };

  return request
    .send({
      url: '/settlements/report/',
      method: 'POST',
      responseType: 'blob',
      params,
    })
    .then(blobDownloadAs(`${fileName}.xlsx`));
};

export const downloadRefundsReport = (from = '', to = '') => {
  const fileName = reportFileName('refunds', from, to);
  const params = {
    created_at_after: from,
    created_at_before: to,
  };

  return request
    .send({
      url: '/refunds/report/',
      method: 'POST',
      responseType: 'blob',
      params,
    })
    .then(blobDownloadAs(`${fileName}.xlsx`));
};

export const downloadOrdersReport = (from = '', to = '') => {
  const fileName = reportFileName('orders', from, to);
  const params = {
    created_at_after: from,
    created_at_before: to,
  };

  return request
    .send({
      url: '/orders/report/',
      method: 'POST',
      responseType: 'blob',
      params,
    })
    .then(blobDownloadAs(`${fileName}.xlsx`));
};

export const downloadInvoice = (id, name) => {
  return request
    .send({
      url: `/orders/${id}/invoice/`,
      method: 'GET',
      responseType: 'blob',
    })
    .then(blobDownloadAs(`${name}.pdf`));
};

export const downloadRefundInvoice = (id, name) => {
  return request
    .send({
      url: `/orders/${id}/refund_invoice/`,
      method: 'GET',
      responseType: 'blob',
    })
    .then(blobDownloadAs(`${name}.pdf`));
};

export const getBankAccounts = () => request.get('/bank-accounts/').then(resp => resp.data);

export const addBankAccount = data => request.post('/bank-accounts/', data).then(resp => resp.data);

export const getBankAccount = id => request.get(`/bank-accounts/${id}/`).then(resp => resp.data);

export const deleteBankAccount = id => request.del(`/bank-accounts/${id}/`).then(resp => resp.data);

export const setDefaultBankAccount = id =>
  request.patch(`/bank-accounts/${id}/`, { is_default: true }).then(resp => resp.data);

export const getQuickStats = () => request.get(`/statistics/`).then(resp => resp.data);

export const getShopifyConfig = () =>
  request.get(`/shopify/configuration/app/`).then(resp => resp.data);

export const updateShopifyConfig = data =>
  request.put(`/shopify/configuration/app/`, data).then(resp => resp.data);

export const updateMechantRole = data => {
  return request.put(`/merchant/roles/${data.role_id}/`, data);
};

export const updateEmployeeRole = data => {
  return request.put(`/merchant/employees/${data.user_id}/`, data);
};

export const updateEmployeeState = data => {
  return request.put(`/merchant/employees/${data.user_id}/`, data);
};

export const deleteShopifyConfig = data =>
  request.del(`/shopify/configuration/app/`, data).then(resp => resp.data);

export const getOrderDraftsList = ({
  fromDate,
  toDate,
  page,
  limit = 5,
  merchant,
  merchantWide,
}) => {
  const queryParams = {
    page,
    per_page: limit,
    created_at_after: fromDate,
    created_at_before: toDate,
    merchant,
    merchant_wide: merchantWide,
  };

  return request.get(`/drafts/`, queryParams).then(pagginagtionResponse);
};

export const patchDraftPlan = (id, data) =>
  request.patch(`/drafts/${id}/`, data).then(resp => resp.data);

export const confirmQrOrder = (
  checkoutId,
  planId,
  paymentMethodId,
  consumer,
  draftId,
  customReference = 'NONE',
  planSlug = '',
) =>
  request
    .patch(
      `/checkouts/${checkoutId}/confirm_qr/?consumer=${consumer}&draft_id=${draftId}&custom_reference=${customReference}`,
      {
        plan_id: planId,
        payment_method_id: paymentMethodId,
        plan_slug: planSlug,
      },
    )
    .then(resp => resp.data);

export const patchSettlementEmails = (id, emails) =>
  request
    .patch(`/merchant/profile/${id}/set_settlement_emails/`, { settlement_emails: emails })
    .then(resp => resp.data);
